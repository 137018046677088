import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import 'animate.css';

import { Configuration, OpenAIApi } from 'openai';
const configuration = new Configuration({
  organization: "org-crUdvBwU1PzB7VQFYlUVULZp",
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

const position = [
  "CTO startup agri-tech",
  "wartawan koran pagi",
  "penyiar radio",
  "pemilik kafe gaul",
  "pelaku UMKM",
  "pengurus koperasi",
  "guru honorer",
  "pedagang kaki lima"
];

const names = [
  "Bobby Sujarwo",
  "Ahmad Deddy",
  "Shinta Pariwara",
  "Carol Aminah",
  "Soemarno",
  "Naih bin Jian",
  "Suprapti Ningsih",
  "Moana Adzkiya"
];

const ages = [
  "18",
  "24",
  "33",
  "45",
  "48",
  "30",
  "28",
  "20"
];


function App() {
  const random = Math.floor(Math.random() * position.length);
  const randomName = Math.floor(Math.random() * names.length);
  const randomAge = Math.floor(Math.random() * ages.length);
  let { who } = useParams();
  const [completion, setCompletion] = useState(null)
  const [actor, setActor] = useState(position[random])
  const [active, setActive] = useState(false)

  const whoAreYou = async (_actor) => {
    if (_actor.length === 0) {
      await setActor(position[random])
      setActive(false)
    } else {
      setActor(_actor)
      setActive(true)
    }
  }

  const generate = async () => {
    setCompletion('')
    let messages = [
      {
        "role": "system",
        "content": `Anda adalah seorang ${actor}. Anda sangat menguasai dan berpengalaman dalam profesi ini. Sehingga dengan pengalaman tersebut, kritik Anda sangat tajam dan konstruktif. `
      }
    ]

    let prompt = {
      "role": "user",
      "content": "Tanpa tendensi untuk mengajak atau mengarahkan kepada apa pun. "
        + "Sampaikan dengan singkat, padat dan terbuka mengenai keresahan terkait teknologi tersentralisasi yang digunakan untuk mendukung aktivitas bekerja, "
        + "sesuai kapasitas Anda dalam menjalankan tugas dan kewenangan Anda.  "
        + "Boleh ditambahi dengan sedikit ilustrasi potensi use case nya seperti apa. "
        + "Ditambah keresahan ini berdampak pada pelayanan terhadap pihak yang Anda layani sesuai profesi ini. "
        + "Semua dikarenakan tidak adanya solusi berbasis teknologi terdesentralisasi di institusi tempat Anda bekerja. "
        + "Boleh ditambahi dengan sedikit ilustrasi potensi masalah nya apa saja. "
        + "Sampaikan kurang dari 150 kata dengan bahasa santai tapi sopan."
    }

    messages.push(prompt);

    await openai.createChatCompletion({
      model: 'gpt-3.5-turbo',
      messages: messages,
      max_tokens: 2500,
      /* temperature: 0.7,
      top_p: 1,
      frequency_penalty: 2.0,
      presence_penalty: 2.0 */
    }).then((gptResponse) => {
      setCompletion(gptResponse.data.choices[0].message.content)
      // console.log(gptResponse.data.choices[0].message.content)
      randomName = Math.floor(Math.random() * names.length);
      randomAge = Math.floor(Math.random() * ages.length);
    }).catch(err => {
      console.log(err.message)
    });
  }

  useEffect(() => {
    generate()
  }, ['completion'])

  return (
    <>
      <header className="ds-header" id="site-header">
        <div className="container">
          <div className="ds-header-inner">
            <a href="/" className="ds-logo">
              <span style={{ transform: 'rotate(270deg)' }}>8</span>MetaFisik Studio
            </a>
            <ul className="ds-social">
              <li><a href="https://github.com/engram-network" target="_blank"><i className="ri-github-fill"></i></a></li>
              <li><a href="https://medium.com/@engramnet" target="_blank"><i className="ri-medium-line"></i></a></li>
              <li><a href="https://linkedin.com/company/engram-network" target="_blank"><i className="ri-linkedin-fill"></i></a></li>
            </ul>
          </div>
        </div>
      </header>

      <div className="ds-about-section">
        <div className="container">

          <section className='mt-5'>
            <br /><br />
            <small>Anda adalah seorang ...</small>
            <div className="input-group">
              <input type="text" className="form-control" placeholder={`misal, ${actor}`} onKeyUp={(e) => whoAreYou(e.target.value)} />
              <button className="btn btn-warning" onClick={() => generate()}>resah?</button>
            </div>
          </section>
          <br /><br />

          {
            !completion && <img className="animate__animated animate__heartBeat animate__infinite" style={{ display: 'block', margin: '0 auto', height: '120px', width: '120px' }} src="assets/images/think.png" />
          }

          {completion && <section>
            <h2 className="ds-heading">Keresahan saya sebagai {actor}.</h2>

            <figure>
              <blockquote class="blockquote">
                <p className='lh-lg text-justify' style={{ textAlign: 'justify' }}>"{completion}"</p>
              </blockquote>
              <figcaption class="blockquote-footer mt-3">
                {names[randomName]}. <cite title={actor}>{ages[randomAge]} tahun, {actor}.</cite>
              </figcaption>
            </figure>
            <div className="alert alert-primary">
              <i>Teknologi terdesentralisasi menjamin privasi, kedaulatan dan keaslian data yang tidak bisa dikendalikan oleh pihak-pihak tertentu.</i>
            </div>

            <div className="ds-button-sec text-center">
              <div className="ds-button" onClick={() => generate()}> Ga gitu? Ulangi </div>
            </div>
          </section>}

        </div>
      </div>

      <footer className="ds-footer text-center">
        <div className="container">
          <section>
            <span>Mengalami hal yang sama?</span>
            <h4>mau ngobrol-ngobrol dulu?</h4>
            <p>mungkin kita bisa cari solusinya</p>
            <a href="https://calendly.com/wawanbsetyawan/30min" target="_blank" className="ds-button">Ngobrol 30 Menit, Yuk</a>
          </section>
          <span className="ds-copyright">
            <strong>© {new Date().getFullYear()} All rights reserved. MetaFisik's decentralized-tech solutions powered by <a
              href="https://engramnet.io/" target="_blank">EnGram Network</a>.</strong>
          </span>
          <br />
          <small className="ds-copyright">
            <i>form ini hanya dipergunakan sebagai survey sederhana untuk menggali potensi pemanfaatan teknologi blockchain di hampir semua aspek pekerjaan maupun profesi.
              detail implementasi maupun target-target khusus yang ingin dicapai bisa didiskusikan lebih jauh bersama team kami.</i>
          </small>
        </div>
      </footer>
    </>
  );
}

export default App;
